//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import config from '@/util/config';
export default {
  data() {
    return {
      moveClass_id: '', //要移动的分类id
      moveClass_name: '',
      classTarget_id: '', //要移动到目标的分类id
      dialogVisible: !1,
      showOptions: [
        {
          value: 0,
          label: '全部',
        },
        {
          value: 1,
          label: '否',
        },
        {
          value: 2,
          label: '是',
        },
      ],
      is_show: 0,
      name: '',
    };
  },
  computed: {
    goodsClass() {
      return this.$store.getters.goodsTypeList;
    },
  },
  created() {
    this.$store.dispatch('goods/getTypeList', {
      is_show: this.is_show,
      name: this.name,
    });
  },
  methods: {
    exportGoodsClass() {
      this.$axios.post(this.$api.goods.exportGoodsClass).then(res => {
        if (res.code == 0) {
          let path = config.baseurl + '/' + res.result;
          let a = document.createElement('a');
          a.href = path;
          a.id = 'download';
          document.body.appendChild(a);
          a.click();
          let aDom = document.getElementById('download');
          document.body.removeChild(aDom);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    editChange(row) {
      this.$axios
        .post(this.$api.goods.editType, {
          id: row.id,
          parent_id: row.parent_id,
          name: row.name,
          picture: row.picture,
          rank: row.rank,
          is_show: row.is_show,
        })
        .then(res => {
          if (res.code == 0) {
            this.$store.dispatch('goods/getTypeList', {
              is_show: this.is_show,
              name: this.name,
            });
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    showValChange(val) {
      this.is_show = Number(val);
    },
    //搜索分类
    searchType() {
      this.$store.dispatch('goods/getTypeList', {
        is_show: this.is_show,
        name: this.name,
      });
    },
    cancelSearch() {
      this.name = '';
      this.is_show = 0;
      this.$store.dispatch('goods/getTypeList', {
        is_show: this.is_show,
        name: this.name,
      });
    },
    //转移商品
    moveGoods(row) {
      this.moveClass_id = row.id;
      this.moveClass_name = row.name;
      this.classTarget_id = '';
      this.dialogVisible = !0;
    },
    //提交转移商品
    moveGoodsSubmit() {
      if (!this.classTarget_id)
        return this.$message({
          message: '请选择要转移目标的分类',
          type: 'warning',
        });
      this.$axios
        .post(this.$api.goods.shiftGoods, {
          id: this.moveClass_id,
          classify_id: this.classTarget_id,
        })
        .then(res => {
          if (res.code == 0) {
            this.dialogVisible = !1;
            this.$confirm('商品转移成功', '提示', {
              confirmButtonText: '确定',
              type: 'success',
            });
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    handleClassChange(val) {
      val.length == 1 ? (this.classTarget_id = val[0]) : (this.classTarget_id = val[1]);
    },
    //编辑分类
    edit(row) {
      this.$router.push({
        path: '/goods/editGoodsType',
        query: {
          info: JSON.stringify(row),
        },
      });
    },
    //删除分类
    remove(row) {
      this.$confirm('确认删除此商品分类？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$axios
          .post(this.$api.goods.delType, {
            id: row.id,
          })
          .then(res => {
            if (res.code == 0) {
              let goodsClass = [...this.goodsClass];
              goodsClass.splice(row.index, 1);
              this.$store.commit('goods/getTypeList', goodsClass);
              this.$message({
                message: '删除成功',
                type: 'success',
              });
            } else {
              this.$message.error(res.msg);
            }
          });
      });
    },
  },
};
